<script setup>
import { onMounted, ref, useSlots } from 'vue';

defineProps({
    modelValue: [String, Number],
    type: {
        type: String,
        default: 'text',
    },
    placeholder: {
        type: String,
        default: '',
    },
    hasErrors: {
        type: Boolean,
        default: false,
    },
    hideIconOnMobile: {
        type: Boolean,
        default: false,
    },
});

defineEmits(['update:modelValue']);
const slots = useSlots();
const hasSlot = (name) => {
    return !!slots[name];
};
const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <span class="relative mt-1.5 flex">
        <input
            ref="input"
            class="form-input peer w-full rounded-default border bg-transparent px-3 py-2 placeholder:text-dark-text-/80 hover:z-10 focus:z-10"
            :placeholder="placeholder"
            :type="type"
            :value="modelValue"
            :class="{
                'border-error': hasErrors,
                'border-accent-light/40 dark:border-accent-dark/40 hover:border-accent-light/60 dark:hover:border-accent-dark/60 focus:border-accent-light dark:focus:border-accent-dark': !hasErrors,
                'pl-9': hasSlot('icon')
                }"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <span
            v-if="hasSlot('icon') && !hideIconOnMobile"
            class="pointer-events-none absolute flex h-full w-10 items-center justify-center text-accent-light peer-focus:text-primary-light dark:text-accent-dark dark:peer-focus:text-accent-dark">
          <slot name="icon" />
        </span>
  </span>
</template>
